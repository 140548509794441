<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col id="desc">
				<h1 class="mb-5">司書課程履修動機尺度</h1>
				<p class="text-subtitle-1" id="scroll-to-0">あなたが司書課程や図書館情報学の専門課程を履修した動機について、24項目でお聞きします。<br>それぞれの項目について、あなたに最もあてはまるものをお選びください。<br>卒業生の方は、履修当時の状況に基づいてお答えください。</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<UserScaleAnswerCard
					v-for="(item, index) in scaleitems"
					:key="'card-' + item.scaleitem.id"
					:scaleitem="item.scaleitem"
					:index="index + 1"
					lang="ja"
				></UserScaleAnswerCard>
				<v-card
					class="mx-auto mt-10"
					max-width="900"
					tile
					flat
					v-if="notAnsweredId.length"
				>
					<p class="mb-5">未回答の項目があります。</p>
					<v-chip
						v-for="item in notAnsweredId"
						:key="'not-answered-' + item"
						class="ma-1"
						color="error"
						outlined
						link
						@click="scrollTo('scroll-to-' + (item - 1))"
					>
						{{ item }}
					</v-chip>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<UserScaleAnswerDownload
					lang="ja"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: -1em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import { mapGetters, mapActions } from 'vuex'
import UserScaleAnswerCard from '@/components/UserScaleAnswerCard'
import UserScaleAnswerDownload from '@/components/UserScaleAnswerDownload'

export default {
	name: 'UserSurveyQ2',
	components: {
		UserScaleAnswerCard,
		UserScaleAnswerDownload
	},
	data () {
		return {
			scaleitems: [
			{
				"order": 1,
				"scaleitem": {
					"id": 1,
					"text": "図書館で働きたいから",
					"factor_id": 1,
					"factor_name": "図書館への就職希望"
				},
				"scale_id": 8
				},
				{
				"order": 2,
				"scaleitem": {
					"id": 2,
					"text": "司書になりたいから",
					"factor_id": 1,
					"factor_name": "図書館への就職希望"
				},
				"scale_id": 8
				},
				{
				"order": 3,
				"scaleitem": {
					"id": 3,
					"text": "司書の仕事に興味があるから",
					"factor_id": 1,
					"factor_name": "図書館への就職希望"
				},
				"scale_id": 8
				},
				{
				"order": 4,
				"scaleitem": {
					"id": 4,
					"text": "司書を将来の職業の選択肢として考えているから",
					"factor_id": 1,
					"factor_name": "図書館への就職希望"
				},
				"scale_id": 8
				},
				{
				"order": 5,
				"scaleitem": {
					"id": 5,
					"text": "資格を取得することで安心したいから",
					"factor_id": 2,
					"factor_name": "資格志向の考え方"
				},
				"scale_id": 8
				},
				{
				"order": 6,
				"scaleitem": {
					"id": 6,
					"text": "何か資格が欲しいから",
					"factor_id": 2,
					"factor_name": "資格志向の考え方"
				},
				"scale_id": 8
				},
				{
				"order": 7,
				"scaleitem": {
					"id": 7,
					"text": "文系の資格が欲しいから",
					"factor_id": 2,
					"factor_name": "資格志向の考え方"
				},
				"scale_id": 8
				},
				{
				"order": 8,
				"scaleitem": {
					"id": 8,
					"text": "多くの資格が欲しいから",
					"factor_id": 2,
					"factor_name": "資格志向の考え方"
				},
				"scale_id": 8
				},
				{
				"order": 9,
				"scaleitem": {
					"id": 9,
					"text": "尊敬している人の職業だから",
					"factor_id": 3,
					"factor_name": "知人の司書への憧れ"
				},
				"scale_id": 8
				},
				{
				"order": 10,
				"scaleitem": {
					"id": 10,
					"text": "学校図書館にいた司書に憧れているから",
					"factor_id": 3,
					"factor_name": "知人の司書への憧れ"
				},
				"scale_id": 8
				},
				{
				"order": 11,
				"scaleitem": {
					"id": 11,
					"text": "公共図書館にいた司書に憧れているから",
					"factor_id": 3,
					"factor_name": "知人の司書への憧れ"
				},
				"scale_id": 8
				},
				{
				"order": 12,
				"scaleitem": {
					"id": 12,
					"text": "知人が司書資格を持っていたから",
					"factor_id": 3,
					"factor_name": "知人の司書への憧れ"
				},
				"scale_id": 8
				},
				{
				"order": 13,
				"scaleitem": {
					"id": 13,
					"text": "本が好きだから",
					"factor_id": 4,
					"factor_name": "本・読書への愛着"
				},
				"scale_id": 8
				},
				{
				"order": 14,
				"scaleitem": {
					"id": 14,
					"text": "読書が好きだから",
					"factor_id": 4,
					"factor_name": "本・読書への愛着"
				},
				"scale_id": 8
				},
				{
				"order": 15,
				"scaleitem": {
					"id": 15,
					"text": "本や読書に関連する資格だから",
					"factor_id": 4,
					"factor_name": "本・読書への愛着"
				},
				"scale_id": 8
				},
				{
				"order": 16,
				"scaleitem": {
					"id": 16,
					"text": "本のある場所/空間が好きだから",
					"factor_id": 4,
					"factor_name": "本・読書への愛着"
				},
				"scale_id": 8
				},
				{
				"order": 17,
				"scaleitem": {
					"id": 17,
					"text": "司書資格が将来の仕事に役立つと思うから",
					"factor_id": 5,
					"factor_name": "司書資格への期待"
				},
				"scale_id": 8
				},
				{
				"order": 18,
				"scaleitem": {
					"id": 18,
					"text": "司書課程の内容が将来の仕事に役立つと思うから",
					"factor_id": 5,
					"factor_name": "司書資格への期待"
				},
				"scale_id": 8
				},
				{
				"order": 19,
				"scaleitem": {
					"id": 19,
					"text": "司書資格は就職活動をする時に役立つと思うから",
					"factor_id": 5,
					"factor_name": "司書資格への期待"
				},
				"scale_id": 8
				},
				{
				"order": 20,
				"scaleitem": {
					"id": 20,
					"text": "司書課程の内容が将来の生活に役立つと思うから",
					"factor_id": 5,
					"factor_name": "司書資格への期待"
				},
				"scale_id": 8
				},
				{
				"order": 21,
				"scaleitem": {
					"id": 21,
					"text": "書店で働きたいから",
					"factor_id": 6,
					"factor_name": "出版業界への就職希望"
				},
				"scale_id": 8
				},
				{
				"order": 22,
				"scaleitem": {
					"id": 22,
					"text": "出版社で働きたいから",
					"factor_id": 6,
					"factor_name": "出版業界への就職希望"
				},
				"scale_id": 8
				},
				{
				"order": 23,
				"scaleitem": {
					"id": 23,
					"text": "本の出版や販売に携わりたいから",
					"factor_id": 6,
					"factor_name": "出版業界への就職希望"
				},
				"scale_id": 8
				},
				{
				"order": 24,
				"scaleitem": {
					"id": 24,
					"text": "図書館以外でも本に関わる仕事がしたいから",
					"factor_id": 6,
					"factor_name": "出版業界への就職希望"
				},
				"scale_id": 8
				}
			],
		}
	},
	computed: {
		...mapGetters({
			scaleItemNotAnswered: 'stateScaleItemNotAnswered',
			scaleItemAnswers: 'stateScaleItemAnswers',
		}),
		testId() {
			return this.$route.params.test_id
		},
		scaleLength() {
			return this.scaleitems.length
		},
		notAnsweredId() {
			return this.getNotAnsweredId()
		}
	},
	methods: {
		...mapActions([
			'setScaleItemNotAnswered',
			'setQ2',
		]),
		async answer() {
			this.setLoading(true)
			const scaleScore = await this.createScaleScore(
				{
					test_id: this.testId,
					user_id: this.userId,
					testanswer_id: this.testAnswer.id
				}
			)
			const payload = {
				testId: this.testId,
				userId: this.userId,
				startAt: this.startAt,
				scaleScoreId: scaleScore.id
			}
			await this.postScaleItemAnswers(payload).then(() => {
				this.setLoading(false)
				this.appendAnsweredScale(this.scale.id)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		getNotAnsweredId() {
			return this.scaleItemNotAnswered
		},
		scrollTo(id) {
			smoothscroll.polyfill()
			document.getElementById(id).scrollIntoView({
				behavior: 'smooth'
			})
		}
	}
}
</script>